const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '/',
  title: 'SG.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Samuel Graham',
  role: 'Final Year Engineering Student',
  description:
        'Final Year Engineering Masters Student at the University of Bath,\nwith a passion for problem-solving.',
  resume: '/Graham_Samuel_CV.pdf',
  social: {
    linkedin: 'https://www.linkedin.com/in/samuel-graham-uob/',
    github: 'https://github.com/sg9372',
  },
}

const introduction = {
  description:
    'I\'m a final year Engineering Masters Student currently studying at the University of Bath. My previous experience consists of both Software and Mechanical Engineering internships, combined with academic success, extracirricular activities, and personal programming projects. I will finish my University studies in May of 2025, where I look forward to applying my skills in industry.'
}

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  { skill_name: 'GitHub', skill_description: 'Proficient in using GitHub for version control, collaboration, and sharing code, with experience in industry-standard practices.' },
  { skill_name: 'C', skill_description: 'Developed various programs in C as part of the CS50 course, gaining hands-on experience with algorithms and problem-solving.' },
  { skill_name: 'C#', skill_description: 'Created a high-performance A-Level computer science coursework application in C#, earning an A* grade.' },
  { skill_name: 'Visual Studio', skill_description: 'Experienced in Visual Studio for developing projects in multiple languages, both for personal use and professional applications.' },
  { skill_name: 'Python', skill_description: 'Developed games and applications in Python during GCSEs, A-levels, and summer projects, enhancing programming and problem-solving skills.' },
  { skill_name: 'MATLAB', skill_description: 'Used MATLAB extensively for data manipulation, mathematical modeling, and graph plotting during university projects.' },
  { skill_name: 'Microsoft Office', skill_description: 'Proficient in Microsoft Office suite, including Word, Excel, and PowerPoint, for professional reports, data analysis, and presentations.' },
  { skill_name: 'Web Development', skill_description: 'Developed personal websites using HTML, CSS, JavaScript, and React, showcasing skills in front-end web development.' },
  { skill_name: 'Spanish', skill_description: 'Upper-intermediate to advanced proficiency in Spanish, self-taught over two years through immersive experiences.' },
  { skill_name: 'Decryption', skill_description: 'Created applications to solve various ciphers, including monoalphabetic substitution, Caesar shift, and Vigenère ciphers.' },
  { skill_name: 'PLC Programming', skill_description: 'Programmed PLCs during a summer internship at 3P Innovation for internal and external projects, contributing to automation solutions.' },
  { skill_name: 'Client Communication', skill_description: 'Participated in client meetings and presentations during my internship at 3P Innovation, effectively communicating project progress.' },
  { skill_name: 'Adaptability', skill_description: 'Demonstrated adaptability by managing up to five simultaneous projects during my internship at 3P Innovation.' },
  { skill_name: 'Teamwork', skill_description: 'Worked on numerous team projects at university and in industry, contributing to a 77% average grade and securing return offers.' },
  { skill_name: 'Time Management', skill_description: 'Successfully balanced multiple academic and professional projects, maintaining high performance and meeting deadlines.' },
  { skill_name: 'Continuous Learning', skill_description: 'I am passionate about continuous learning and regularly refresh and improve my skills in programming and emerging technologies.'},
  { skill_name: 'Database Management', skill_description: 'Experience with SQL databases for data storage, manipulation, and querying in various programming projects'}
]

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: <pre>
            {'Personal\nProjects'}
          </pre>,
    description:
      'Personal projects include this website, CS50 programs, apps to assist with learning Spanish.\nClick here to see more.',
    stack: ['Web Development', 'CS50', 'Python'],
    link: '/personalprojects'
  },
  {
    name: <pre>
            {'University &\nA-Level Projects'}
          </pre>,
    description:
      'Including apps to simulate drone trajectory and model heat transfer through spacecraft walls.\nClick here to see more.',
    stack: ['MATLAB', 'Python'],
    link: '/universityprojects'
  },
  {
    name: <pre>
            {'Active\nProjects'}
          </pre>,
    description:
      'I\'m currently working on a variety of both personal and University projects, such as decryption software and simulation models.',
    stack: ['Pyhton', 'Encryption', 'Data Sets'],
  },
  
]

const experiences = [
  {
    name: 'Software Engineer - 3P Innovation',
    description:
      'In the summer before the final year of my engineering masters degree, after receiving a return offer, I re-joined 3P Innovation as a Software Engineer. I learnt the 3P coding structure and delivered a working test rig within 3 weeks of re-joining the company, demonstrating my ability to learn quickly and work with tight time constraints within new teams. My performance resulted in a Software Engineering graduate offer.',
    stack: ['Software Development', 'Rapid Learning', 'GitHub'],
  },
  {
    name: 'English Teaching - Madrid, Spain',
    description:
      'During the summer of 2024, I worked as an AuPair in Spain, living with a host family and teaching English. I led English classes for small groups of students at a local youth centre, learning to teach students of differing abilities with no prior experience, demonstrating my organisational skills and adaptability to new situations. Whilst in Spain, I fully immersed myself in the culture and greatly improved my Spanish skills.',
    stack: ['Adaptability', 'Teaching', 'Language Proficiency'],
  },
  {
    name: 'Maths Tutor - CE Education',
    description:
      'I currently tutor A-Level maths for 4 hours per week alongside studying at university, bringing one student from a C grade to an A* within my first 4 months of teaching.',
    stack: ['Organisation', 'Problem-Solving', 'Teaching'],
  },
  {
    name: 'Mechanical Engineer - 3P Innovation',
    description:
      'During my 12-month placement at 3P Innovation, an engineering consultancy, I served as an engineer, contributing to numerous projects for both internal and external stakeholders. This experience proved invaluable, enhancing countless skills. This significantly broadened my skill set, making me a more versatile and dependable engineer.',
    stack: ['Stakeholder Collaboration', 'Versitility', 'Adaptability'],
  },
]

const educations = [
  {
    name: 'University of Bath - 77% Average',
    description: 
      'I am currently studying the final year of my Mechanical Engineering Masters (MEng) at the University of Bath. My standout modules include; Heat Transfer - 96%, Control Systems – 94%,  Instrumentation & Electronics – 87%, Mathematics 2 – 84%, Systems & Control – 84%.\n\nWhilst at the University of Bath, I have not only maintained academic excellence, but also created strong relationships through playing football, kickboxing, or going out with friends.',
    stack: ['Engineering', 'Time-management', 'Sport'],
  },
  {
    name: 'Lutterworth College - A* A* A* A*',
    description:
      'I studied four A-Levels (Maths, Further Maths, Computer Science and Physics) during Sixth Form, achieveing an A* in each. Additionally, balancing study with passions outside of college, such as training in Brazillian Jiu-Jitsu and seeing friends, worked as excellent preperation for the active and busy university lifestyle which I enjoy today.',
    stack: ['Computer Science', 'Mathematics'],
  },
  {
    name: 'CS50 - C, C#, Python',
    description:
      'In the summer of 2023, due to my passion for learning and my deep interest in computer science and programming, I enrolled in Harvards CS50 online course. This experience allowed my to try new programming languages, refresh my skills in old ones, and deepen my understanding of fundamental computer science principles.\n\nAdditionally, performing well within the self-study confines of the course demonstrated my self-motivation, discipline, and ability to work hard regardless of circumstances.',
    stack: ['Self-study', 'Computer Science'],
  },
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'sg2361@bath.ac.uk',
}

export {header, about, introduction, projects, skills, contact, experiences, educations}
